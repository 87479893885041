import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BedFinder from "../components/BedFinder/BedFinder"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const BedFinderPage = ({ location }) => {
  const {
    localWpGraphQlPages: {
      pageId: post_id,
      seo: seo,
      heading: { subTitle },
      featuredImage,
    },
  } = useStaticQuery(BED_FINDER_PAGE_META)

  return (
    <BedFinder
      location={location}
      defaultProductType={"beds"}
      post_id={post_id}
      seo={seo}
      subTitle={subTitle}
      featuredImage={featuredImage}
    />
  )
}

export default BedFinderPage

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const BED_FINDER_PAGE_META = graphql`
  {
    localWpGraphQlPages(slug: { eq: "bed-finder-2" }) {
      pageId
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      featuredImage {
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      heading {
        subTitle
      }
    }
  }
`
